import React from 'react';
import {Col, Row} from 'react-bootstrap';
import atob from 'atob';

import Layout from '../../components/layout';

import {productsBase64} from '../../assets/pagehero-base64/products-base64';

// import {hexomaticScreen} from '../../assets/products/hexomatic-screen.js';
// import {hexowatchScreen} from '../../assets/products/hexowatch-screen.js';
// import {hexometerScreen} from '../../assets/products/hexometer-screen.js';

import {
  hexomaticIcon,
  hexometerIcon,
  hexosparkIcon,
  hexowatchIcon,
  hexofyIcon,
  searchscraperIcon,
} from '../../assets/svg-icons';

import g2 from '../../assets/products/ratings/g2.svg';
import getApp from '../../assets/products/ratings/getApp.svg';
import capterra from '../../assets/products/ratings/capterra.svg';
import productHunt from '../../assets/products/ratings/product-hunt.svg';

import './styles.scss';

const ratings = [
  {
    name: 'GetApp',
    image: getApp,
    value: '4.8/5',
    linkTo: 'https://www.getapp.com/website-ecommerce-software/a/hexometer/',
  },
  {
    name: 'Capterra',
    image: capterra,
    value: '4.8/5',
    linkTo: 'https://www.capterra.com/p/198619/Hexometer/reviews/',
  },
  {
    name: 'Product Hunt',
    image: productHunt,
    value: '4.9/5',
    linkTo: 'https://www.producthunt.com/posts/hexometer-2',
  },
  {
    name: 'G2.com',
    image: g2,
    value: '4.9/5',
    linkTo:
      'https://www.g2.com/products/hexometer/reviews?__cf_chl_jschl_tk__=3ca982c5f6b76f56b7dcfffe3923c0af659ca984-1599738892-0-ASvYCSGB6_EWtBYuu_eeUv8D-dp1P0Gf0eQC7jJ8acorMP5lItkuFXnmC2p3dTm16uAi_QmIBoZ-hjhQLg-0LCKQmZ2CjCWpYXCBHFdcymv7rQZRXjVy3iOj4zSqPcVNFfm5duH-yxRucpbWpJs0n3wEPxCqEIyL3ATSkQUb1fMrEUcMuRDdKYKXLfl01CuuaOzkrNzi5Euaf8uRKBsdeONTJ2ScShlIhTxsTW-C-vBn7eN21qysxCh-zmJ3UFRhJWmegWUEy-2kuWPUEA5-pXVaaZaNkB1AI4gRMYIWYUcF_2mDZojx5-Kx5kNTtPtJkA#survey-response-4360759',
  },
];

const Products = () => {
  return (
    <Layout
      pageheroColor="#FFC500"
      pageheroImg={atob(productsBase64)}
      pageheroText="The automation revolution has started. Tap into the true power of the cloud with scalable business solutions designed for businesses of all sizes."
      pageheroTitle="Hexact Ecosystem"
    >
      <div className="products-section">
        <Row className="products-section-1">
          <Col lg={6} className="d-none d-lg-flex">
            <img src="https://hexact.io/products/hexomatic.png" alt="Hexomatic" />
          </Col>
          <Col lg={6} className="content-container">
            <h2 className="page-title">
              {hexomaticIcon}{' '}
              <a href="https://hexomatic.com" target="_blank" style={{color: '#050038'}}>
                Hexomatic
              </a>
            </h2>
            <img
              src="https://hexact.io/products/hexomatic.png"
              alt="Hexomatic"
              className="d-lg-none my-2"
            />
            <p>
              Hexomatic is your no-code growth hacking sidekick, enabling universal access to the internet as your own
              data source, leveraging the most sophisticated AI services to automate and delegate time-consuming tasks.
            </p>
            <p>
              Create your own bots in minutes to extract information from any data source and leverage ready-made
              automations to scale.
            </p>

            <a href="https://hexomatic.com" target="_blank" className="explore-btn">
              Explore Hexomatic
            </a>
          </Col>
        </Row>
        <Row className="products-section-2">
          <Col lg={6} className="content-container">
            <h2 className="page-title">
              {hexowatchIcon}{' '}
              <a href="https://hexowatch.com" target="_blank" style={{color: '#050038'}}>
                Hexowatch
              </a>
            </h2>
            <img
              src="https://hexact.io/products/hexowatch.png"
              alt="Hexowatch"
              className="d-lg-none my-2"
            />
            <p>Monitor any website for visual, content, source code, technology, availability, or price changes.</p>
            <p>
              Stay ahead of your market and receive notifications when important changes are detected on landing pages,
              products, competitors, clients, partners, or industry websites.
            </p>

            <a href="https://hexowatch.com" target="_blank" className="explore-btn">
              Explore Hexowatch
            </a>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img src="https://hexact.io/products/hexowatch.png" alt="Hexowatch" />
          </Col>
        </Row>
        <Row className="products-section-3">
          <Col lg={6} className="d-none d-lg-flex">
            <img src="https://hexact.io/products/searchscraper.jpg" alt="Search Scraper" style={{maxWidth: '450px'}} />
          </Col>
          <Col lg={6} className="content-container">
            <h2 className="page-title">
              {searchscraperIcon}{' '}
              <a href="https://searchscraper.io/" target="_blank" style={{color: '#050038'}}>
                Search Scraper
              </a>
            </h2>
            <img
              src="https://hexact.io/products/searchscraper.jpg"
              alt="Search Scraper"
              className="d-lg-none my-2"
              style={{maxWidth: '450px'}}
            />
            <p>
              Get 1-Click access to Google search, Maps, News, or Images search results in a convenient spreadsheet
              format, just like magic.
            </p>
            <a href="https://searchscraper.io/" target="_blank" className="explore-btn">
              Explore Search Scraper
            </a>
          </Col>
        </Row>
        <Row className="products-section-4">
          <Col lg={6} className="content-container">
            <h2 className="page-title">
              {hexosparkIcon}{' '}
              <a href="https://hexospark.com" target="_blank" style={{color: '#050038'}}>
                Hexospark
              </a>
            </h2>
            <img
              src="https://hexact.io/products/hexospark.png"
              alt="Hexospark"
              className="d-lg-none my-2"
            />
            <p>Hexospark is an all-in-one email and CRM platform designed to spark productivity.</p>
            <p>
              Personalize emails, automate inbound follow-ups and grow your sales by nurturing relationships in one
              centralized platform.
            </p>

            <a href="https://hexospark.com" target="_blank" className="explore-btn">
              Explore Hexospark
            </a>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img src="https://hexact.io/products/hexospark.png" alt="Hexofy" />
          </Col>
        </Row>
        <Row className="products-section-3">
          <Col lg={6} className="d-none d-lg-flex">
            <img src="https://hexact.io/products/hexofy.png" alt="Hexofy" />
          </Col>
          <Col lg={6} className="content-container">
            <h2 className="page-title">
              {hexofyIcon}{' '}
              <a href="https://hexofy.com" target="_blank" style={{color: '#050038'}}>
                Hexofy
              </a>
            </h2>
            <img
              src="https://hexact.io/products/hexofy.png"
              alt="Hexofy"
              className="d-lg-none my-2"
            />
            <p>
              Hexofy captures data from any page, like magic.
              <br />
              Say goodbye to copy-pasting with super simple 1-click web scraping you can use anywhere from your browser.
            </p>
            <a href="https://hexofy.com" target="_blank" className="explore-btn">
              Explore Hexofy
            </a>
          </Col>
        </Row>
      </div>
      <div className="reviews-section">
        <div className="row">
          {ratings.map(item => (
            <div key={item.name} className="col-md-3 col-sm-6 col-xs-12 text-center rating-block">
              <span>
                <img
                  src={item.image}
                  alt={item.name}
                  height={20}
                  width={item.name === 'G2.com' ? 25 : 84}
                  style={{
                    height: '20px',
                    width: item.name === 'G2.com' ? '25px' : '84px',
                  }}
                />
                <span className="f_700 ml-2">{item.value}</span>
                <ul className="d-flex p-0" style={{justifyContent: 'center'}}>
                  <li className="stars">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="star"
                      className="svg-inline--fa fa-star fa-w-18"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      color="#f3ad16"
                    >
                      <path
                        fill="currentColor"
                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                      ></path>
                    </svg>
                  </li>
                  <li className="stars">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="star"
                      className="svg-inline--fa fa-star fa-w-18"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      color="#f3ad16"
                    >
                      <path
                        fill="currentColor"
                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                      ></path>
                    </svg>
                  </li>
                  <li className="stars">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="star"
                      className="svg-inline--fa fa-star fa-w-18"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      color="#f3ad16"
                    >
                      <path
                        fill="currentColor"
                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                      ></path>
                    </svg>
                  </li>
                  <li className="stars">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="star"
                      className="svg-inline--fa fa-star fa-w-18"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      color="#f3ad16"
                    >
                      <path
                        fill="currentColor"
                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                      ></path>
                    </svg>
                  </li>
                  <li className="stars">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="star"
                      className="svg-inline--fa fa-star fa-w-18"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      color="#f3ad16"
                    >
                      <path
                        fill="currentColor"
                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                      ></path>
                    </svg>
                  </li>
                </ul>
              </span>
            </div>
          ))}
        </div>
        <div className="reviews-text">We're innovating at a remarkable rate</div>
      </div>
    </Layout>
  );
};

export default Products;
